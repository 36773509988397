/* fonts from https://www.fonts-online.ru/fonts/pc */
/* advanced crossplatform font integration tips: https://stackoverflow.com/questions/24990554/how-to-include-a-font-ttf-using-css */

@font-face {
    font-family: 'Jura Light';
    src: url('20783.ttf');
}
@font-face {
    font-family: 'Inter Regular';
    src: url('20709.otf');
}
@font-face {
    font-family: 'Inter Bold';
    src: url('20705.otf');
}