.FiveGraphicsDrawerFileId {
    text-align: center;
    background-color: white;
}

div.FiveGraphicsDrawer-plotter-array {
    flex-direction: column;
}

div.FiveGraphicsDrawerFileId-plotter-array-wait {
    margin: 0;
    padding: 10px;
    background-color: #e7e430;
}

div.FiveGraphicsDrawerFileId-plotter-array-error {
    margin: 0;
    padding: 10px;
    background-color: #db3131;
}