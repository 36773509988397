.FiveGraphicsDrawer {
    text-align: center;
    background-color: white;
}

div.FiveGraphicsDrawer-upload-pane {
    background-color: #72e88c;
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: calc(10px + 1.5vmin);
    color: black;
}

div.FiveGraphicsDrawer-plotter-array {
    flex-direction: column;
}

div.FiveGraphicsDrawer-plotter-array-wait {
    margin: 0;
    padding: 10px;
    background-color: #e7e430;
}

div.FiveGraphicsDrawer-plotter-array-error {
    margin: 0;
    padding: 10px;
    background-color: #db3131;
}