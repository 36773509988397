/* Grid styling */
/* see: https://tproger.ru/translations/css-flexbox-grid/ */

/* Table styling */
.ProfilePage-root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.ProfilePage-solid-combo-popup-bg{
    background-color: white;
}