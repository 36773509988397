.Popup-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
}

.Popup-modal.Popup-active {
    opacity: 1;
    transition: 0.5s;
    pointer-events: all;
}

.Popup-modal-content {
    padding: 20px;
    max-width: 90%;
    border-radius: 9px;
    box-shadow: 0 0 5px 5px #254750;
    background-color: deepskyblue;
    transform: scale(0.7);
    transition: 0.3s;
}

.Popup-modal-content.Popup-active {
    transform: scale(1);
}