.MenuButton-button {
    color: #333333;
    border:solid #00000000;
    border-width: 1px 0;
    cursor:pointer;
    vertical-align:middle;
    padding: 5px;
    text-align: center;
}
.MenuButton-button:hover {
    border:solid #CCC;
    border-width: 1px 0;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
}
.MenuButton-button:active {
    color: #002897;
    box-shadow: 0 0 5px -1px rgba(255, 255, 255, 0.6);
}