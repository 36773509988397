@import "resources/fonts/fonts.css";

.App {
    text-align: center;
    font:inherit;
    font-family: 'Inter Regular', serif;
}

h1, h2, h3, h4, h5, h5 {
    color: #3e3e3e;
    line-height: 0.2;
    font-family: 'Inter Bold', serif;
}

.App-logo-holder {
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App-logo {
    height: 15vmin;
    padding: 10px;
    pointer-events: none;
}

.App-header {
    height: 15vmin;
    background-color: #c6dbff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.App-body {
    background-color: #7299e8;
    min-height: 85vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.App-space-background {
    /* https://stackoverflow.com/questions/32796399/css-make-div-slide-over-another-div-i-e-use-a-div-as-background */
    background-image: url("resources/backgrounds/spacex-iridium-launch-033018.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
}

.App-login-box {
    margin: 10px;
}

.App-login-prompt {
    font-family: 'Inter Regular', serif;
    color: whitesmoke;
    font-size: 3vmin;
    margin: 10px;
}

.hidden {
    display: none;
}

.App-menu {
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 10px;
    min-width: 15vw;
    font-size: calc(10px + 2vmin);
}

.App-content-with-footer {
    font-size: calc(10px + 1.5vmin);
    color: black;
    padding: 2vh;
    background-color: white;
    text-align: left;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.App-content {
    flex-grow: 1;
}

.App-footer {
}

.App-grid-wrapper {
    display: table;
}
.App-grid-element {
    display: table-cell;
}

.App-link {
    color: #61dafb;
}

.App-centering {
    text-align: center;
}

.App-centering > img {
    max-width: 100%
}

a.App-no-decoration {
    color: #071343;
    text-decoration: none; /* no underline */
}

