.SocialMediaInfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SocialMediaInfo-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.SocialMediaButton-vcenter-icon-and-visible-url {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Contacts section */
.ContactsInfo-button-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
}

/* Buttons style section */
.SocialMediaButton-caption {

}
.SocialMediaButton-icon-size {
    height: calc(max(20px, 6vmin));
    width: calc(max(20px, 6vmin));
}
.SocialMediaButton-button {
    color: #333333;
    cursor:pointer;
    vertical-align:middle;
    padding: 5px;
    text-align: center;
}

/* TODO should be better solution though... this may not work in many browsers, but maybe that's ok... */
.SocialMediaButton-facebook:hover {
    filter: invert(48%) sepia(79%) saturate(20000%) hue-rotate(222deg) brightness(140%) contrast(119%);
}
.SocialMediaButton-instagram:hover {
    filter: invert(48%) sepia(79%) saturate(20000%) hue-rotate(333deg) brightness(146%) contrast(119%);
}
.SocialMediaButton-youtube:hover {
    filter: invert(48%) sepia(79%) saturate(20000%) hue-rotate(0deg) brightness(118%) contrast(119%);
}
.SocialMediaButton-vk:hover {
    filter: invert(48%) sepia(79%) saturate(20000%) hue-rotate(190deg) brightness(122%) contrast(119%);
}
.SocialMediaButton-telegram:hover {
    filter: invert(48%) sepia(79%) saturate(20000%) hue-rotate(197deg) brightness(132%) contrast(119%);
}