/* Grid styling */
/* see: https://tproger.ru/translations/css-flexbox-grid/ */

/* Table styling */
.FileList-root {
    display: grid;
    grid-template-columns: 40% 25% 7% 13% 15%; /* name, desc, size, date, etc */
    background-color: white;
    grid-gap: 1px;
    font-size: 0.8em;
}

/* Element styling */
.FileList-header-elem {
    background-color: #b3b3b3;
    display: flex;
    flex-direction: column;
}

.FileList-elem {
    background-color: #f7f7ff;
    border-right: inherit;
    margin-right: -1px;
    border-bottom: inherit;
    margin-bottom: -1px;
    padding: 1vm;
}

.FileList-unknown-element {
    color: gray;
}

.FileList-small-icons {
    height: 1.6em;
}
